<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div>
          <b-button
            size="sm"
            :to="{
              name: 'admin.customer.detail',
              params: { id: customer.id },
            }"
            class="mr-2"
            ><i class="fa fa-chevron-left"></i> Буцах</b-button
          >

          <h4 class="mt-3">
            {{ customer.lastname }} {{ customer.firstname }} /{{
              customer.phoneNumber
            }}/
          </h4>
          <hr />
        </div>
        <b-form @submit.stop.prevent="save()">
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Эрхийн хэмжээ"
                label-for="input-username"
              >
                <money
                  id="input-username"
                  class="form-control"
                  v-model="form.loanMaxAmount"
                  type="text"
                  required
                  placeholder="Эрхийн хэмжээ"
                  suffix=""
                  thousands=","
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Өр орлогын харьцаа"
                label-for="input-payDescription"
              >
                <money
                  id="input-username"
                  class="form-control"
                  v-model="form.debtRatio"
                  type="text"
                  required
                  placeholder="Өр орлогын харьцаа"
                  suffix=""
                  thousands=""
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-alert :show="zms_accept == null" variant="info" class="mb-0">
                Хэрэглэгчийн ЗМС - г шалгана уу
                <b-button
                  variant="info"
                  size="sm"
                  class="ml-3"
                  @click="checkZms"
                  >ЗМС шалгах</b-button
                >
              </b-alert>
              <b-alert
                :show="zms_accept == false"
                variant="danger"
                class="mb-0"
              >
                ЗМС шаардлага хангахгүй байна
                <b-button
                  variant="danger"
                  size="sm"
                  class="ml-3"
                  @click="checkZms"
                  >ЗМС шалгах</b-button
                >

                <b-button size="sm" v-if="internationalData.zms_error" variant="danger" class="pull-right" @click="showZms">
                  {{ internationalData.zms_error }}
                </b-button>
              </b-alert>
              <b-alert
                :show="zms_accept == true"
                variant="primary"
                class="mb-0"
              >
                ЗМС хэвийн байна
              </b-alert>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-alert :show="xyp_accept == null" variant="info" class="mb-0">
                Хэрэглэгчийн ХУР мэдээллийг татна уу
                <b-button
                  variant="info"
                  size="sm"
                  class="ml-3"
                  @click="checkXyp"
                  >ХУР татах</b-button
                >
              </b-alert>
              <b-alert
                :show="xyp_accept == false"
                variant="danger"
                class="mb-0"
              >
                Хэрэглэгчийн ХУР мэдээллийг татаагүй байна
                <b-button
                  variant="danger"
                  size="sm"
                  class="ml-3"
                  @click="checkXyp"
                  >ХУР татах</b-button
                >
              </b-alert>
              <b-alert
                :show="xyp_accept == true"
                variant="primary"
                class="mb-0"
              >
                ХУР мэдээлэл татсан
              </b-alert>
            </b-col>
          </b-row>

          <hr />

          <h4>
            Файл
            <b-button size="sm" variant="link" @click="showModal"
              >нэмэх</b-button
            >
          </h4>

          <b-alert variant="warning" :show="files.length == 0">
            Файл байхгүй байна
          </b-alert>

          <b-row>
            <b-col sm="6">
              <b-list-group>
                <b-list-group-item :key="key" v-for="(item, key) in files">
                  <b-button
                    :href="item.filePath"
                    target="_blank"
                    variant="success"
                    size="sm"
                    class="pull-right"
                    >файл</b-button
                  >
                  <h6 class="mt-2">{{ item.description }}</h6>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <hr />
          <b-button type="submit" variant="primary">Хадгалах</b-button>
        </b-form>
      </b-card>
    </b-container>

    <b-modal ref="my-modal" title="Файл оруулах" hide-footer>
      <loading
        :active.sync="isLoadingImage"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="uploadFile()">
        <b-form-group label="Файл:" label-for="input-change-phone">
          <b-input-group>
            <b-form-file
              v-model="new_file.file"
              :state="Boolean(new_file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Тайлбар">
          <b-form-input v-model="new_file.description"></b-form-input>
        </b-form-group>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal ref="zms-modal" title="Зээл" hide-footer size="xl">
      <loading
        :active.sync="isLoadingImage"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-row>
        <b-col>

          <table
      class="table table-sm table-bordered mb-0"
      stacked="md"
      hover
      striped
      bordered
      small
    >
      <tr style="padding-top: 30px" class="bg-light">
        <th class="text-center">№</th>
        <th>Зээлийн төрөл</th>
        <th>Зээл өгсөн байгууллага</th>
        <th>Зээл олгосон огноо</th>
        <th>Зээл төлөгдөж дуусах огноо</th>
        <th>Хаасан огноо</th>
        <th>Олгосон дүн</th>
        <th>Үлдэгдэл</th>
        <th class="text-center">Зээлийн ангилал</th>
      </tr>
      <tbody>
        <tr v-for="(loan, index) in internationalData.zms_log" :key="index" >
          <td class="text-center">{{ index + 1 }}</td>
          <td>{{ loan.dutytypename }}</td>
          <td>{{ loan.clientname }}</td>
          <td class="text-center">{{ loan.adv_date }}</td>
          <td class="text-center">{{ loan.maturity_date }}</td>
          <td class="text-center">{{ loan.closed_date }}</td>
          <td class="text-right">{{ loan.adv_amount | currecryZero }}</td>
          <td class="text-right">{{ loan.balance | currecryZero }}</td>
          <td class="text-center">{{ loan.cls_name }}</td>
        </tr>
      </tbody>
      </table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Uploader from "../../../../store/image-uploader-international";

export default {
  name: "new-incomeinfo",
  data: function() {
    return {
      isLoadingImage: false,
      isLoading: false,
      customer: null,
      zms_accept: null,
      xyp_accept: null,
      form: {
        loanMaxAmount: 0,
        debtRatio: 0.0,
      },
      files: [],
      new_file: {
        file: null,
        filePath: null,
        description: "",
      },
      internationalData: null,
    };
  },
  watch: {
    "form.loanMaxAmount": {
      handler(loanMaxAmount) {
        if (loanMaxAmount > 1000000) {
          this.$data.form.loanMaxAmount = 1000000;
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadData();
    this.getInternationCode();
  },
  methods: {
    getInternationCode: function() {
      console.log("sadadads");
      this.isLoading = true;
      this.$data.internationalData = null;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_international_user",
          {
            params: {
              id: this.$route.params.id,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log("asdfasdfasdfhaskldfhaskdhfakl jdaskljdfh asklhf");
            console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.internationalData = {
                is_confirm: response.body.isConfirm,
                confirm_date: response.body.confirmDate,
                id: response.body.id,
                is_accept: response.body.isAcceptZms
              };
              let zmsError = response.body.zmsError
              let zmsLog = response.body.zmsLog

              if(zmsError !== undefined) {
                this.$data.internationalData['zms_error'] = zmsError
                this.$data.internationalData['zmsLog'] = JSON.parse(zmsLog)
              } else {
                this.$data.internationalData['zms_error'] = 'Муу зээлийн түүхтэй'
                this.$data.internationalData['zms_log'] = [{"adv_amount":138160,"cls_name":"Муу","colls":[{"state_regno":"","coll_regno":"","sub_coll_type":null}],"purp_code":null,"closed_date":null,"curr_code":"MNT","clientid":null,"loancode":null,"description":"gurvalsan_uilchilgee","maturity_date":"2016-01-20","purp_name":"","clientlastname":null,"dutytype":"LS","maturity_date2":null,"balance":51760,"clientname":"Юнител ХХК","custid":null,"int_rate":0,"adv_date":"2015-12-01","cls_code":null,"dutytypename":"Түрээс","monthly_payment":0}]
              }
              this.$data.zms_accept = this.$data.internationalData.is_accept;
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    loadData: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_customer_info", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.customer = response.body;
            this.$data.form = {
              loanMaxAmount: this.$data.customer.limit,
              debtRatio: this.$data.customer.debtRatio,
            };
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_internation_files",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.files = response.body.data;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    showModal() {
      this.$data.new_file = {
        file: null,
        filePath: null,
        description: "",
      };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    uploadFile: function() {
      const uploader = new Uploader();
      this.$data.isLoadingImage = true;
      if (this.$data.new_file.file) {
        const logo = {
          contentType: this.$data.new_file.file.type,
          fileName: this.$data.new_file.file.name,
          file: this.$data.new_file.file,
          folder: this.$route.params.id + "/",
        };
        uploader.uploadImage(logo, this.$data.new_file.type).then(
          (response) => {
            this.$data.new_file.filePath = response.Location;
            this.$data.isLoadingImage = false;
            this.saveFile();
          },
          (response) => {
            this.$data.isLoadingImage = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
      }
    },
    saveFile: function() {
      this.$data.isLoadingImage = true;
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/save_internation_file",
          {
            customer_id: this.$route.params.id,
            file_path: this.$data.new_file.filePath,
            description: this.$data.new_file.description,
          },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.$data.isLoadingImage = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.hideModal();
              this.$data.files.push({
                id: response.body.id,
                filePath: response.body.filePath,
                description: response.body.description,
                createdDate: response.body.createdDate,
              });
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
          },
          (response) => {
            this.$data.isLoadingImage = true;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    checkZms: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/check_international_zms",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response)
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              let zmsData = JSON.parse(response.body.successData)
              console.log(zmsData)
              this.$data.zms_accept = response.body.successData;
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    showZms: function() {
      this.$refs["zms-modal"].show();
    },
    checkXyp: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/check_international_xyp",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.xyp_accept = response.body.successData;
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    save: function() {
      if (this.$data.zms_accept == null) {
        this.showToast("Анхаар", "ЗМС шалгаагүй байна", "warning");
      } else if (this.$data.zms_accept == false) {
        this.showToast("Анхаар", "ЗМС шаардлага хангахгүй байна", "danger");
      } else {
        if (this.$data.xyp_accept == null) {
          this.showToast("Анхаар", "ХУР татаагүй байна", "warning");
        } else if (this.$data.xyp_accept == false) {
          this.showToast("Анхаар", "ХУР татаагүй байна", "danger");
        } else {
          this.$data.isLoading = true;
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/save_income_info",
            {
              customer_id: this.$data.customer.id,
              limit: this.$data.form.loanMaxAmount,
              debtRatio: this.$data.form.debtRatio,
            },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.$data.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              this.$data.isLoading = true;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
        }
        
      }
    },
  },
};
</script>
