import AWS from "aws-sdk";

class Uploader {

    constructor() {
        AWS.config.update({
            region: "us-east-1",
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: "us-east-1:a5fe896a-6b5f-4e9b-952c-9359123b3bef"
            })
        });

        this.s3 = new AWS.S3();
    }

    uploadImage(payload) {
        let s3 = this.s3;
        return new Promise(function(resolve, reject) {

            let decodedImage = payload.file;
            // let decodedImage = Buffer.from(encodedImage, 'base64');

            const filePath = "merchant/international/" + payload.folder + payload.fileName;
            const params = {
                "Body": decodedImage,
                "Bucket": "qr-numur-bucket",
                "Key": filePath,
                "ACL": "public-read",
                "ContentType": payload.contentType
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        })
    }
}

export default Uploader;